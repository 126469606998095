<template>
  <div>
    <div>
      <a-form :form="form" :wrapperCol="{xxl:20,xl:15}" :labelCol="{xxl:4,xl:9}">
        <a-row gutter="20">
          <a-col
            :xxl="6">
            <a-form-item
              label="客户名称">
              <a-select></a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="8">
            <a-form-item>
              <a-button type="primary" icon="search">搜索</a-button>
              <!--              <a-button class="margin-left-16" icon="export">导出数据</a-button>-->
            </a-form-item>

          </a-col>
          <a-col
            :xxl="10">
            <div class="float-right">
              <a-button type="primary" icon="plus" @click="addCustomer">添加客户</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="ant-card">
      <s-table
        ref="table"
        :data="loadData"
        :columns="columns">
        <span slot="status" slot-scope="text, record">
          <a-tag v-if="record.status==1" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a style="color: red" @click="deleteCustomer(record.id)">删除</a>
          <a-divider type="vertical" />
          <!--          <a @click="showUserDetail(record.member_id)">禁用</a>-->
          <!--          <a-divider type="vertical" />-->
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down"/>
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="editAdmin(record)">设置管理员</a>
              </a-menu-item>
              <!--              <a-menu-item>-->
              <!--                <a href="javascript:;">编辑信息</a>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item>-->
              <!--                <a @click="showOrder(record.id)">订单明细</a>-->
              <!--              </a-menu-item>-->
            </a-menu>
          </a-dropdown>
        </span>
      </s-table>
    </div>

  </div>
</template>

<script>

import { STable } from '@/components'
import { deleteUnit, loadUnitList } from '@/api/pc'
import EditAdmin from '@/views/unit/EditAdmin'
import ShowOrderList from './ShowOrderList'
import EditCustomer from './EditCustomer'
export default {
  name: 'WeighRecord',
  components: {
    STable
  },
  data () {
    return {
      form: this.$form.createForm(this),
      columns: [
        {
          title: '客户名称',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '订单数',
          dataIndex: 'order_num',
          align: 'center'
        },
        {
          title: '订单总量',
          dataIndex: 'sum_num',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }],
      queryParams: {},
      loadData: parameter => {
        return loadUnitList(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      }
    }
  },
  methods: {
    showUserDetail (member) {

    },
    deleteCustomer (id) {
      var _this = this
      this.$confirm({
        title: '提示',
        content: '确认删除该客户吗？',
        onOk: function () {
          deleteUnit({ id: id }).then(res => {
            if (res.code === 0) {
              _this.$refs.table.refresh()
            }
          })
        }
      })
    },
    editAdmin (unit) {
      this.showModal(EditAdmin, '编辑管理员信息', 400, 270, { unit: unit })
    },
    showOrder (id) {
      this.showModal(ShowOrderList, '订单列表', 500, 600, { id: id })
    },
    addCustomer () {
      this.showModal(EditCustomer, '编辑客户名称', 400, 150)
    },
    reloadTable (firstPage) {
       this.$refs.table.refresh(firstPage)
    }
  }
}
</script>

<style scoped>

</style>
