<template>
  <div style="width: 100%;padding: 8px;">
    <div>
      <a-input placeholder="请输入客户名称" v-model="name"></a-input>
    </div>
    <div class="margin-top-20">
      <div class="float-right">
        <a-button @click="closeWin">取消</a-button>
        <a-button class="margin-left-16" type="primary" @click="saveCustomerInfo">确定</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveCustomerName } from '@/api/pc'
export default {
  name: 'EditCustomer',
  data () {
    return {
      name: ''
    }
  },
  props: ['layerid', 'customer'],
  created () {
    if (this.customer) {
      this.name = this.customer.name
    }
  },
  methods: {
    closeWin () {
      this.$layer.close(this.layerid)
    },
    saveCustomerInfo () {
       if (this.name.length === 0) {
         this.$message.error('输入客户名称不能为空')
         return null
       }
      saveCustomerName({ name: this.name }).then(res => {
       this.$parent.reloadTable(this.customer === null)
        this.closeWin()
      })
    }
  }
}
</script>

<style scoped>

</style>
