<template>
  <div style="width: 100%;padding: 8px;">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" @submit="handleSubmit">
      <a-form-item label="用户名">
        <a-input v-decorator="['account', { rules: [{ required: true, message: '请输入用户名!' }] }]"></a-input>
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-decorator="['password', { rules: [{ required: true, message: '请输入登录密码!' }] }]"></a-input>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 5 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import md5 from 'md5'
import { saveUnitAdminInfo } from '@/api/pc'
export default {
  name: 'EditAdmin',
  props: ['unit', 'layerid'],
  data () {
    return {
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log(this.unit)
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
           saveUnitAdminInfo({
             account: values.account,
             pwd: md5(values.password),
             unit_name: this.unit.name,
             unit_id: this.unit.id
           }).then(res => {
              if (res.code === 0) {
                this.$message.success('保存成功')
                this.$layer.close(this.layerid)
              } else {
                this.$message.error(res.msg)
              }
           })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
